  <form [formGroup]="manageForm" (ngSubmit)="onSubmit(manageForm)" class="m-0 p-0">
  <div class="row d-flex justify-content-center align-items-center p-0 m-0">
      <!-- <div class="col-4 px-0 m-0">
      <div class="row m-0"> -->
        <ng-container *ngIf="updateList.Message"><h3 class="m-0 text-center mb-4" style="color:red">{{updateMessg}}</h3></ng-container>
        <h1 class="col-9 text-center m-0 mb-3">Update Password</h1>
        <div class="col-lg-9 col-md-6 col-sm-10 col-xs-10 border-top pt-2">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>New Password</mat-label>
            <input autocomplete="off" matInput type="password" formControlName="account_pwd">
          </mat-form-field>
          <mat-error *ngIf="(manageForm.get('account_pwd')?.dirty || formSubmited) && manageForm.controls['account_pwd']?.hasError('required')"><small>Password is required</small>
          </mat-error>
          <mat-error *ngIf="manageForm.controls['account_pwd'].hasError('pattern')">Invalid Password Format</mat-error>
        </div>
        <div class="col-lg-9 col-md-6 col-sm-10 col-xs-10">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Confirm Password</mat-label>
            <input autocomplete="off" matInput type="password" formControlName="confirm_pwd">
          </mat-form-field>
          <mat-error *ngIf="(manageForm.get('confirm_pwd')?.dirty || formSubmited) && manageForm.controls['confirm_pwd'].hasError('required')"><small>Password is required</small>
          </mat-error>
          <mat-error *ngIf="manageForm.get('confirm_pwd')?.dirty &&  !(manageForm.controls['confirm_pwd'].hasError('required')) && (manageForm.value.confirm_pwd !== manageForm.value.account_pwd)"><small>Password mismatch</small>
          </mat-error>
        </div>
      <!-- </div>
    </div> -->
    <p>
      <small><ul><li>The password must not contain any sequence of four consecutive digits.</li><li>It must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.</li><li>The total length of the password must be between 8 and 20 characters.</li></ul>
      </small>
      </p>
    <div class="col-9 text-center">
      <button class="me-3" mat-stroked-button type="button" color="primary"
    color="primary" matTooltip="cancel" (click)="CloseForm(1)">Cancel</button>
      <button type="submit" mat-raised-button color="primary"  class="ms-3" >Update</button>

    </div>
  </div>
  </form>

